<template>
  <div class="big-box">
    <div class="head-box">
      <el-button type="primary" size="medium" @click="newAdd" v-if="$has('add')">新增</el-button>
      <el-button type="success" @click="editStatus(1)" size="medium" v-if="$has('change')">上线</el-button>
      <el-button type="info" @click="editStatus(0)" size="medium" v-if="$has('change')">下线</el-button>
      <el-button type="danger" @click="delMolude" size="medium" v-if="$has('del')">删除</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" tooltip-effect="dark" @selection-change="handleSelectionChange"
          style="width: 90%; margin-left: 40px">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="id" align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="goods_name" align="center" label="商品名称" min-width="120">
          </el-table-column>
          <el-table-column prop="image_url" align="center" min-width="180" label="商品图片">
            <template slot-scope="scope">
              <img style="max-width: 100px;max-height: 100px" @click="checkPhoto(scope.row.image_url)"
                :src="scope.row.image_url" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="goods_scores_price" align="center" min-width="100" label="积分价格">
          </el-table-column>
          <el-table-column prop="goods_inventory_num" align="center" min-width="80" label="库存数量">
          </el-table-column>
          <el-table-column prop="goods_exchange_num" align="center" min-width="80" label="已兑换数量">
          </el-table-column>
          <el-table-column prop="updated_at" align="center" min-width="150" label="发布时间">
          </el-table-column>
          <el-table-column prop="goods_user_name" align="center" min-width="80" label="操作员">
          </el-table-column>
          <el-table-column prop="goods_status" align="center" min-width="80" label="状态">
            <template slot-scope="scope">
              <span :style="{color: scope.row.goods_status == 1 ? '' : 'red'}">{{scope.row.goods_status == 1 ? '已上线' :
              '待上线'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="date" align="center" min-width="180" label="操作" v-if="$has('edit')">
            <template slot-scope="scope">
              <el-button type="primary" @click="detailEdit(scope.row)" size="small">修改</el-button>

            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-left: 50px;margin-top: 10px" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page.sync="page" :page-size="10"
          layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </template>
    </div>
    <el-dialog :visible.sync="dialogPvVisible" width="550px" :title="title === '0'? '新增商品': '编辑商品'">
      <el-form label-width="150px" :inline="true">
        <el-form-item required label="商品名称:">
          <el-input maxlength="25" placeholder="请输入商品名称" v-model="goods_name"></el-input>
        </el-form-item>
        <el-form-item required label="积分价格:">
          <el-input type="number" placeholder="请输入积分价格" v-model="goods_scores_price" oninput="if(value<1)value=1"></el-input>
        </el-form-item>
        <el-form-item required label="库存数量:">
          <el-input type="number" placeholder="请输入库存数量" v-model="goods_inventory_num" oninput="if(value>999)value=999;if(value.length>4)value=value.slice(0,4);if(value<1)value=1"></el-input>
        </el-form-item>
        <el-form-item required label="图片id:">
          <el-input placeholder="请输入图片id" v-model="goods_img_id"></el-input>
          <el-button type="primary" @click="checkImg(goods_img_id)">查看</el-button>
        </el-form-item>
        <!-- <el-form-item required label="模块类型:">
          <el-select v-model="types" placeholder="--请选择-- ">
            <el-option label="单图活动Banner" :value='2'></el-option>
            <el-option label="轮播Banner" :value='1'></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEdit">取消</el-button>
        <el-button type="primary" @click="save">确认</el-button>
      </span>
    </el-dialog>
    <CheckPhoto ref="CheckPhoto" :imgId='imgId' :imgUrl="imgUrl"></CheckPhoto>
  </div>
</template>
<script>
import {
  homepageCreate,
  getHomelist,
  homepageUpdate,
  goods_delete
} from "@/api/data.js";
import CheckPhoto from "@/components/CheckPhoto.vue";
export default {
  components: { CheckPhoto },
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      status: '',
      goods_id: 0,
      goods_name: '',
      goods_scores_price: '',
      goods_inventory_num: '',
      goods_img_id: '',
      title: '0',
      imgId: '',
      imgUrl: '',
      imgList: [
        {},
      ],
      dialogPvVisible: false,
      multipleSelection: [],
      codes: '',
      titles: '',
      types: '',
      tableData: [],
      roles: ['admin', 'user'],
    };
  },
  created() {
  },
  watch: {},
  mounted() {
    this.search();
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val
      this.search()
    },
    newAdd() {
      this.title = '0'
      this.dialogPvVisible = true
      this.goods_name = ''
      this.goods_scores_price = ''
      this.goods_inventory_num = ''
      this.goods_img_id = ''
    },
    cancelEdit() {
      this.dialogPvVisible = false
      this.search();
    },
    detailEdit(item) {
      this.dialogPvVisible = true
      this.title = 1
      this.goods_id = item.id
      this.goods_name = item.goods_name
      this.goods_scores_price = item.goods_scores_price
      this.goods_inventory_num = item.goods_inventory_num
      this.goods_img_id = item.goods_img_id
    },
    checkImg(item) {
      this.imgId = item + ''
      this.$refs.CheckPhoto.open();
    },
    save() {
      const valid = this.dataValidation()
      if (valid) {
        homepageCreate(
          {
            id: this.title === '0' ? '' : Number(this.goods_id),
            goods_name: this.goods_name,
            goods_scores_price: Number(this.goods_scores_price),
            goods_inventory_num: Number(this.goods_inventory_num),
            goods_img_id: Number(this.goods_img_id),
          }
        ).then(({ data: res }) => {
          if (res.code === 200) {
            this.$message.success('保存成功');
            this.dialogPvVisible = false
            this.search()
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    dataValidation() {
      if (!this.goods_name) {
        this.$message.error('商品名称不能为空')
        return false
      }
      if (!this.goods_scores_price) {
        this.$message.error('积分价格不能为空')
        return false
      }
      if (!this.goods_inventory_num) {
        this.$message.error('库存数量不能为空')
        return false
      }
      if (this.goods_inventory_num > 999) {
        this.$message.error('库存数量不能超过999')
        return false
      }
      if (!this.goods_img_id) {
        this.$message.error('图片id不能为空')
        return false
      }
      return true
    },
    editStatus(type) {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请选择");
        return;
      }
      if (type === 1) {
        const isAllOffline = this.multipleSelection.every(function (item, index, arr) {
          return item.goods_status === 0;
        });
        if (!isAllOffline) {
          this.$message.error("选中数据非全为待上线状态");
          return
        }
      }
      if (type === 0) {
        const isAllOnline = this.multipleSelection.every(function (item, index, arr) {
          return item.goods_status === 1;
        });
        if (!isAllOnline) {
          this.$message.error("选中数据需均为已上线状态才可处理");
          return
        }
      }
      this.$confirm("是否" + (type == 1 ? '上线' : '下线') + "商品", "警告", {
        distinguishCancelAndClose: true,
        confirmButtonText: "取消",
        cancelButtonText: "确认",
      })
        .then(() => { })
        .catch((action) => {
          if (action === "cancel") {
            let arr = [];
            arr = this.multipleSelection.map(function (item, index, arr) {
              return item.id;
            });
            homepageUpdate({
              ids: arr,
              goods_status: type
            }).then(({ data: res }) => {
              if (res.code === 200) {
                this.$message.success('操作成功');
                this.page = 1
                this.search();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        });
    },
    delMolude() {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请选择");
        return;
      }
      const isAllOffline = this.multipleSelection.every(function (item, index, arr) {
        return item.goods_status === 0;
      });
      if (!isAllOffline) {
        this.$message.error("选中数据需均为待上线状态才可处理");
        return
      }
      this.$confirm("一旦删除数据，将不可恢复！", "警告", {
        distinguishCancelAndClose: true,
        confirmButtonText: "取消",
        cancelButtonText: "确认",
      })
        .then(() => { })
        .catch((action) => {
          if (action === "cancel") {
            let arr = [];
            arr = this.multipleSelection.map(function (item, index, arr) {
              return item.id;
            });
            goods_delete({
              ids: arr,
            }).then(({ data: res }) => {
              if (res.code === 200) {
                this.$message.success('删除成功');
                this.page = 1
                this.search();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        });
    },
    search() {
      getHomelist(
        `?page=${this.page}&limit=10`
      ).then(({ data: res }) => {
        if (res.code === 200) {
          this.tableData = res.data.data;
          this.total = res.data.total
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style lang="less" scoped>
.head-box {
  height: 40px;
  margin: 20px 0 10px 40px;
}

.add-image {
  text-align: center;
  color: #3ea8d6;
}

.demo-form-inline {
  margin-top: 22px;
  padding-left: 50px;
}

.input-box {
  width: 120px;
  margin-right: 10px;
}

::v-deep .el-input {
  width: 260px;
}
</style>
